<template>
  <!--
        列表展示
    -->
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr class="th">
          <th>市州</th>
          <th>区县</th>
          <th>乡镇</th>
          <th>村组</th>
          <th class="text-center">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in $store.state.sichuanList" :key="item.id">
          <td>{{ item.city_state }}</td>
          <td>{{ item.district_county }}</td>
          <td>{{ item.township }}</td>
          <td>{{ item.village }}</td>
          <td class="text-center">
            <router-link
              :to="{
                path: '/sichuanedit',
                query: { item: JSON.stringify(item) },
              }"
              >编辑</router-link
            ><span v-html="'\u00a0\u00a0\u00a0'"></span>
            <a class="delete" @click="handleClick(item.id)">删除</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      resArr: [],
    };
  },
  mounted() {
    if (this.$store.state.sichuanList.length === 0) {
      this.$store.dispatch("getSichuanListAction");
    } else {
      // console.log('使用缓存数据')
    }
  },
  methods: {
    handleClick(id) {
      // console.log({ id });

      this.$confirm("此操作将永久删除该条目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            .delete("/data_tobacco/delSichuanTobaccoBasicData", {
              params: { id },
            })
            .then((res) => {
              if (res.data.code != 200) {
                this.$message.error("操作失败, 请检查服务器");
                return;
              }
              this.$message({
                message: "删除成功",
                type: "success",
              });
              // 更新数据
              this.$store.commit("deleteSichuanListMutation", id);
            });
        })
        .catch(() => {
          //   this.$message.info("已取消删除");
        });
    },
  },
};
</script>